@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.banner-img{
    background-image: url('./assets/images/banner.jpg');
    background-size: cover; 
    background-position: center;
}